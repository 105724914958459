<template>
    <el-table v-if="carsData.length > 0" :data="carsData">
        <el-table-column prop="name" :label="$t('cars.name')" width="200" />
        <el-table-column prop="license_plate" :label="$t('cars.license_plate')" width="180" />
        <el-table-column prop="holder" :label="$t('cars.holder')" width="260">
            <template slot-scope="scope">
                <router-link v-if="scope.row.holder.uuid" :to="`/employees/${scope.row.holder.uuid}/information`" class="hover:text-red-500 hover:font-semibold">
                    {{ `${scope.row.holder.name} ${scope.row.holder.surname}` }}
                </router-link>
            </template>
        </el-table-column>
        <el-table-column prop="note" :label="$t('cars.note')" show-overflow-tooltip />
        <el-table-column width="80">
            <template slot-scope="scope">
                <el-button-group>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.edit')" placement="left">
                        <el-button type="primary" size="mini" @click="handleEdit(scope.row.id)">
                            <fa-icon :icon="['far', 'edit']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('common.archive')" placement="top">
                        <el-button type="warning" size="mini" @click="handleArchive(scope.row.id)">
                            <fa-icon :icon="['fas', 'archive']" fixed-width />
                        </el-button>
                    </el-tooltip>
                </el-button-group>
            </template>
        </el-table-column>
    </el-table>
</template>
<script>
export default {
    props: {
        carsData: {
            type:    Array,
            default: () => [],
        },
    },

    methods: {
        handleEdit(id) {
            this.$emit('handleEdit', id);
        },

        handleArchive(id) {
            this.$emit('handleArchive', id);
        },
    },
};
</script>
